<template>
  <div>
    <b-card no-body class="mb-0">
      <licence-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewLicenceSidebarActive"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>entries</label> -->
          </b-col>

          <b-col cols="12" md="6" class="right">
            <div class="d-flex align-items-center justify-content-end">
            <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            <b-button
              variant="primary"
              @click="isAddNewLicenceSidebarActive = true"
            >
              <span class="text-nowrap">{{ $t("Add Licence") }}</span>
            </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="filtered_licence_data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
        :empty-filtered-text="$t('There are no records matching your request!')"
      >

      <!-- STATUS -->
       <!-- <template #cell(is_active)="data">
          <b-badge
            style="margin: 3px; padding-top: 5px; min-width: 50px"
            :variant="data.item.is_active === 'PASSIVE' || data.item.is_active === 'PASİF'  ? 'danger' :'success'"
          >
            {{ data.item.is_active === "PASSIVE" || data.item.is_active === "PASİF"  ? $t("Deactivate")  : $t("Activate") }}
          </b-badge>
        </template>  -->


       <template #cell(actions)="data">
       <b-button
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"

            :variant="data.item.is_active === 'PASSIVE' || data.item.is_active === 'PASİF'  ? 'outline-success' :'outline-danger'"
            @click="success(data.item.studio_licence_id,data.item.is_active)"
            >{{ data.item.is_active === "PASSIVE" || data.item.is_active === "PASİF"  ? $t("Activate")  : $t("Deactivate") }}</b-button
          >
        </template>


       </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- <b-col
cols="12"
sm="6"
class="d-flex align-items-center justify-content-center justify-content-sm-start"
>
<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
</b-col> -->
          <!-- Pagination -->
          <b-col
            cols="12"
            sm=""
            class="
            d-flex
            align-items-right
            justify-content-right justify-content-sm-end
            radius 0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total_item"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import LicenceAddNew from "./studio_add_new_licence.vue";
import Ripple from "vue-ripple-directive";

import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import moment from 'vue-moment'
import {mapActions, mapGetters} from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    LicenceAddNew,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  data() {
    return {
      currentPage: 1,
      total_item: 1,
      isSortDirDesc: true,
      sortBy: "id",
      searchQuery: "",
      perPage: 25,
      studio_id: "",
      perPageOptions: [10, 25, 50, 100],
      isAddNewLicenceSidebarActive: false,
      company_branch_id: "ae49b7fd-6b8a-402c-b7ee-f14989e432f3",
      tableColumns: [
        { key: "start_date", label: this.$t("START DATE"), sortable: true },
        { key: "end_date", label: this.$t("END DATE"), sortable: true },
        // { key: "is_active", label: this.$t("STATUS"), sortable: true },
        {
          key: "remaining",
          label: this.$t("REMAINING DAY"),
          sortable: true,
        },
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],
    };
  },
  computed: {
    ...mapGetters('distributorStore',['licence_data']),

    filtered_licence_data() {
      let data = [];
      this.licence_data.map((x) => {
        if (
          x.start_date.toUpperCase().includes(this.searchQuery.toUpperCase())) {
          data.push(x);
          this.total_item = data.length;
          this.currentPage = 1;
        }
      });
      // let _data = data.slice(
      //   (this.currentPage - 1) * this.perPage,
      //   (this.currentPage - 1) * this.perPage + this.perPage
      // );
      // return _data.length === 0 ? data : _data;
      return data
    },
  },
  watch: {
    licence_data(val) {
      this.total_item = val.length;
    },
  },
  methods: {
    ...mapActions('distributorStore',['GET_LICENCE_DATA']),
    success(val,is_active){
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('Are You Sure You Want To Change The Licence Activity Status?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if(is_active === 'PASSIVE' || is_active === 'PASİF' ){
        this.$database.DistributorService.licence_deactive(val,'1').then(res => {
          if (!res.is_success) {
            this.$functions.Messages.error('Action failed!')
            this.get_data();
          }
          else {
            this.$functions.Messages.success('Licence status changed')
          }
            this.get_data();
          })
      }
      if(is_active === 'ACTIVE' || is_active === 'AKTİF' ){
        this.$database.DistributorService.licence_deactive(val,'0').then(res => {
          if (!res.is_success) {
            this.$functions.Messages.error('Action failed!')
            this.get_data();
          }
          else {
            this.$functions.Messages.success('Licence status changed')
          }
            this.get_data();
          })
      }

        }
      })
    },
    get_data() {
      this.GET_LICENCE_DATA({p_company_branch_id:this.studio_id})

    },
  },
  created() {
    this.studio_id = this.$route.params.studioID;
    this.get_data();
  },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     console.log(to);
  //     to.meta.breadcrumb[2].text = 'set';
  //     to.meta.pageTitle
  //   })
  // }
};
</script>

<!-- stillendirme -->
<style src="@/styles/css/studioLicence.css" scoped></style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss" src="@/styles/scss/studioLicence.scss"></style>
