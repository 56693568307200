<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    no-header
    right
  >
    <template #default="{ hide }">
      <form-wizard
        ref="transferWizardIndex"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Kaydet"
        back-button-text="Geri"
        class="mb-3"
      >
        <tab-content :title="$t('Licence')">
            <div>
                <label for="example-input">{{$t("Start Date")}}</label>
                <b-input-group class="mb-1">
                  <flat-pickr
                    v-model="start_value"
                    :placeholder="placeholder"
                    class="form-control"
                    :config="startDateConfig"
                    @blur="$v.start_value.$touch()"
                  />
                  <small v-if="isSubmit && !$v.start_value.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                </b-input-group>
            </div>
            <div>
                <label for="example-input">{{$t("End Date")}}</label>
                 <b-input-group class="mb-1">
                  <flat-pickr
                    v-model="end_value"
                    :placeholder="placeholder"
                    class="form-control"
                    :config="endDateConfig"
                    @blur="$v.end_value.$touch()"
                  />
                 <small v-if="isSubmit && !$v.end_value.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                </b-input-group>
            </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-right">
            <b-button
              v-if="props.activeTabIndex === 0"
              @click="validationForm()"
              size="md"
              variant="primary"
            >
              {{ $t("Save") }}
            </b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button
              v-if="props.activeTabIndex === 0"
              size="md"
              class="mr-1"
              variant="primary"
              @click="hide"
            >
              {{ $t("Back") }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BRow,
  BCol,
  BVToastPlugin,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
  BButton,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {mapActions} from "vuex"
import store from "@/store";
import {required} from "vuelidate/lib/validators";

import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BCardText,
    BVToastPlugin,
    BFormDatepicker,
    FormWizard,
    TabContent,
    BFormGroup,
    BTabs,
    ToastificationContent,
    BTab,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    flatPickr
    // Form Validation
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
      personData: {},
      res_type: {},
      emailValue: "",
      studio_id: "",
      min_date: "",
      max_date: "",
      start_value: "",
      end_value: "",
      max_value: "",
      formatted: "",
      selected: "",
      end_min_date: new Date().toISOString().split('T')[0].split('-').reverse().join(),
      placeholder: "dd.mm.YYYY",
      startDateConfig: {
        dateFormat: "d.m.Y",
        locale: this.$i18n.locale === 'en' ? '' : Turkish,
        minDate: new Date().toISOString().split('T')[0].split('-').reverse().join()
      },
      endDateConfig: {
        dateFormat: "d.m.Y",
        locale: this.$i18n.locale === 'en' ? '' : Turkish,
        minDate: new Date().toISOString().split('T')[0].split('-').reverse().join()
      },
      required
    };
  },
  validations: {
    start_value: {
      required
    },
    end_value: {
      required
    }
  },
  methods: {
    ...mapActions('distributorStore',['GET_LICENCE_DATA']),
    get_max_date() {
      this.$database.DistributorService.get_licence_date(this.studio_id).then(
        (res) => {
          if (!res.is_success) return;
          this.min_date = res.result.max;
        }
      );
    },
    create_licence() {
      this.$database.DistributorService.create_licence(
        this.studio_id,
        this.start_value.split('.').reverse().join('-'),
        this.end_value.split('.').reverse().join('-')
      ).then((res) => {
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Action failed!"),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Licence Successfully Identified"),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.GET_LICENCE_DATA({p_company_branch_id: this.studio_id})
          this.$emit("update:is-add-new-user-sidebar-active",false)
        }
      });
    },
    validationForm() {
      if (this.$refs.transferWizardIndex.activeTabIndex === 0) {
        // return new Promise((resolve, reject) => {
        //   this.$refs.accountRules.validate().then((success) => {
        //     if (success) {
        //       this.create_licence();
        //       resolve(true);
        //     } else {
        //       reject();
        //     }
        //   });
        // });
        if(this.$v.start_value.required && //valid doğrulanırsa
           this.$v.end_value.required){
             this.create_licence();
        }else {
            this.isSubmit = true;
        }
      }
    },
    email_next() {
      this.$database.DistributorService.check_studio_user(
        this.studio_id,
        this.emailValue
      ).then((res) => {
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.result,
              icon: "XIcon",
              variant: "danger",
            },
          });
          return;
        }
        this.res_type = res.result;
        this.$refs.transferWizardIndex.nextTab();
      });
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    create_user() {
      this.$database.DistributorService.create_studio_user(
        this.studio_id,
        this.personData.name,
        this.personData.name,
        this.emailValue
      ).then((res) => {
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Kayıt Gerçekleştirilemedi",
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Kayıt Gerçekleştirildi",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    changeStartDate(val) {
      this.end_value = val;
    }
  },
  watch: {
    start_value(val){
      this.endDateConfig.minDate = val;
    },
  },
  created() {
    this.studio_id = this.$route.params.studioID;
    this.get_max_date();
  },
};
</script>

<style scoped>
input {
  min-width: 80% !important;
}
</style>

<style lang="scss" src="@/styles/scss/studioAddNewLicence.scss"></style>
